import ProductUpCard from '@/components/product/product-up-card'
import { useState } from 'react'
import Icon from '@/components/icon/icon'
import { removePx } from '@/lib/helpers'
import styleConfig from '@/styles/style-config'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import { categoryPageGTM } from '@/lib/gtm'

const ProductSlider = (props) => {
  const [slider, setSlider] = useState(null)
  const [productsViewed, setProductsViewed] = useState([])

  //filter only valid products.
  let products = props.products

  //GTM
  if (productsViewed && productsViewed.length > 0) {
    categoryPageGTM(productsViewed)
    setProductsViewed([])
  }
  //

  return (
    <>
      <div className="product-slider relative z-20">
        {slider != null && products.length > 4 && (
          <div className="product-slider__arrows absolute right-0 hidden lg:block">
            <button
              className="product-slider__arrow product-slider__arrow--prev"
              onClick={() => slider.slidePrev()}
              aria-label="previous"
            >
              <Icon
                className={`cursor-pointer`}
                viewBox={`0 0 36 28`}
                size={28}
                icon="slide-arrow-left"
              />
            </button>
            <button
              className="product-slider__arrow product-slider__arrow--next"
              onClick={() => slider.slideNext()}
              aria-label="next"
            >
              <Icon
                className={`cursor-pointer`}
                viewBox={`0 0 36 28`}
                size={28}
                icon="slide-arrow-right"
              />
            </button>
          </div>
        )}
        {products.length > 0 && (
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={10}
            breakpoints={{
              [removePx(styleConfig.theme.screens.lg)]: {
                slidesPerView: props.perPage ? props.perPage : 4,
              },
            }}
            onSwiper={(swiper) => {
              setSlider(swiper)
            }}
          >
            {products.length > 0 &&
              products.map((product, key) => {
                return (
                  <SwiperSlide className="product-slider__slide" key={key}>
                    <ProductUpCard
                      productsViewed={productsViewed}
                      setProductsViewed={setProductsViewed}
                      type={props.type}
                      product={product}
                      productAlt={[]}
                    />
                  </SwiperSlide>
                )
              })}
          </Swiper>
        )}
      </div>
    </>
  )
}
export default ProductSlider
