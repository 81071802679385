import RecomSliderProductCard from '@/components/account-comp/RecomSliderProductCard'
import { useState } from 'react'
import { removePx } from '@/lib/helpers'
import Icon from '@/components/icon/icon'
import styleConfig from '@/styles/style-config'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'


const RecomSliderLayout = (props) => {

  const [slider, setSlider] = useState(null)

  return (
    <>
      <div className="relative z-20 ">
      {
          (slider != null && props.products && props.products.length > 4) &&
          <div className="product-slider__arrows absolute right-0 hidden lg:block">
            <button className="product-slider__arrow product-slider__arrow--prev" onClick={() => slider.slidePrev()} aria-label="previous">
              <Icon className={`cursor-pointer`} viewBox={`0 0 36 28`} size={28} icon="slide-arrow-left"/>
            </button>
            <button className="product-slider__arrow product-slider__arrow--next" onClick={() => slider.slideNext()} aria-label="next">
              <Icon className={`cursor-pointer`} viewBox={`0 0 36 28`} size={28} icon="slide-arrow-right" />
            </button>
          </div>
        }
        {
          (props.products.length > 0) &&
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={10}
            breakpoints={{
              [removePx(styleConfig.theme.screens.lg)]: {
                slidesPerView: 3
              }
            }}
            onSwiper={swiper => {
              setSlider(swiper)
            }}
          >
            {
               (props.products.length > 0) && props.products.map((product,index) => (
                <SwiperSlide className="product-slider__slide " key={index}>
                    <RecomSliderProductCard
                      type={props.type}
                      product={product}
                      productAlt={[]}
                      key={index}
                      perPage={props.perPage}
                    />
                </SwiperSlide>
              ))
            }
          </Swiper>
        }
      </div>
    </>
  )
}
export default RecomSliderLayout;