import ProductUpCard from '@/components/product/product-up-card'
import ProductSlider from '@/components/product/product-slider'
import useScreenBreakpoint from '@/hooks/useScreenBreakpoint'

const ProductLayout4Up = (props) => {

  const breakpoint = useScreenBreakpoint()

  return (
    <>
      {
        !['xs','sm'].includes(breakpoint) &&
          <div className="hidden grid-cols-4 gap-3 md:grid">
          {
            props.products.length > 0 &&
            props.products.map((product, index) => {
              if (index <= 3) {
                return <ProductUpCard
                          product={ product }
                          productAlt={ []}
                          key={ index }
                          />;
              }
            })
          }
        </div>
      }
      <div className="md:hidden">
        <ProductSlider {...props} perPage={4}/>
      </div>
    </>
  )
}

export default ProductLayout4Up;