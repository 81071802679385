import { useState, useEffect } from 'react'
import { debounce } from '@/lib/lodash'
import { useDispatch } from 'react-redux'
import * as types from '@/redux/actionTypes'

const useScreenBreakpoint =  () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const breakpoints = {
      xs: window?.matchMedia('(max-width: 375px)'),
      sm: window?.matchMedia('(min-width: 376px) and (max-width: 640px)'),
      md: window?.matchMedia('(min-width: 641px) and (max-width: 768px)'),
      lg: window?.matchMedia('(min-width: 769px) and (max-width: 1024px)'),
      xl: window?.matchMedia('(min-width: 1025px)')
    }

    const handleScreenResize = async () => {
      let newBreakpoint = null

      for (const breakpoint in breakpoints) {
        if (breakpoints[breakpoint].matches) {
          newBreakpoint = breakpoint
        }
      }

      setCurrentBreakpoint(newBreakpoint)
      dispatch({
        type: types.UPDATE_BREAKPOINT_SUCCESS,
        payload: {
          breakpoint: newBreakpoint
        }
      })
    }

    const debouncedHandler = debounce(handleScreenResize,100)
    handleScreenResize()
    window.addEventListener('resize', debouncedHandler)

    return () => {
      window.removeEventListener('resize', debouncedHandler)
      // debouncedHandler.cancel()
    }

  },[])

  return currentBreakpoint
}

export default useScreenBreakpoint