import styleConfig from '@/styles/style-config'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import Icon from '../icon/icon'
import { addForwardSlash } from '@/lib/helpers'

const ProductDetails = (props) => {
  const [headline, setHeadline] = useState(props.productContent && props.productContent.headline)

  useEffect(() => {
    let replaceString =
      props.productContent &&
      props.productContent.headline &&
      props.productContent.headline.replace('*', '<em>')
    if (replaceString) {
      const index = replaceString.lastIndexOf('*')
      replaceString =
        replaceString.slice(0, index) + replaceString.slice(index).replace('*', '</em>')
      setHeadline(replaceString)
    }
  }, [props.headline])
  const options = {
    renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  }
  return (
    <>
      <div className="product-details">
        <div
          className={`border border-solid flex flex-row content-section content-section justify-center items-center bg-border border-gray-200 py-8 md:py-10 lg:py-6 xl:py-20 px-5 md:px-12 lg:px-7 xl:px-20`}
        >
          <div>
            {props.productContent && props.productContent.eyebrow && (
              <p
                className="product-content-eyebrow text-xsm pl-1 mb-5 font-tertiary font-normal text-orange-500 uppercase"
                style={{ lineHeight: '16.34px' }}
              >
                {props.productContent.eyebrow}
              </p>
            )}
            <h2
              className="product-content-headline mb-5 xl:mb-7 font-light font-primary text-xl sm:text-2xl text-40 text-55"
              dangerouslySetInnerHTML={{ __html: headline }}
            ></h2>
            {props.productContent && props.productContent.bodyText !== null && (
              <div className="text-sm lg:text-base mb-6 md:mb-7 xl:mb-10 leading-18 lg:leading-20">
                {documentToReactComponents(props.productContent.bodyText.json, options)}
              </div>
            )}
            {props.productContent && props.productContent.ctaLeftUrl && (
              <div className="flex items-center">
                <Link
                  href={addForwardSlash(props.productContent.ctaLeftUrl)}
                  className="product-content-ctalefturl font-primary font-thin border-b flex-no-wrap border-orange-500 hover:text-gray-600 active:text-gray-400"
                >
                  {props.productContent.ctaLeftText}
                </Link>
                <Icon
                  className={`cursor-pointer ml-3 mt-1`}
                  viewBox={`0 0 12 12`}
                  size={12}
                  fill={'#E36F22'}
                  icon="arrow"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .bg-border {
            background: linear-gradient(
              to top left,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0) calc(50% - 0.6px),
              rgb(231, 232, 233) 50%,
              rgba(0, 0, 0, 0) calc(50% + 0.6px),
              rgba(0, 0, 0, 0) 100%
            );
          }

          .text-28 {
            font-size: 28px;
          }

          .product-content-headline {
            line-height: 30px;
          }

          .product-content-ctalefturl {
            line-height: 15px;
            font-size: 15px;
          }

          @media (min-width: ${styleConfig.theme.screens.md}) {
            .product-content-headline {
              line-height: 34px;
            }

            .product-content-ctalefturl {
              line-height: 18px;
              font-size: 18px;
            }
          }

          @media (min-width: ${styleConfig.theme.screens.lg}) {
            .text-40 {
              font-size: 40px;
            }

            .content-section {
              height: ${(props.contentHeight && props.contentHeight + 'px') ||
              'calc(100% - 112px)'};
            }

            .product-content-headline {
              line-height: 38px;
            }
          }

          @media (min-width: ${styleConfig.theme.screens.xl}) {
            .text-55 {
              font-size: 55px;
            }

            .content-section {
              height: ${(props.contentHeight && props.contentHeight + 'px') ||
              'calc(100% - 127px)'};
            }

            .product-content-headline {
              line-height: 53px;
            }
          }
        `}
      </style>
    </>
  )
}
export default ProductDetails
