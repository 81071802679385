import ProductDetails from './product-details'
import ProductSlider from './product-slider'
import { useEffect, useState } from 'react'
import ProductUpCard from './product-up-card'

const ProductTextContent = (props) => {
  const [contentHeight, setContentHeight] = useState(null)
  const [isMobile, setIsMobile] = useState(false)

  const setScreen = () => {
    setIsMobile(window.innerWidth >= 1024 ? false : true)
  }

  useEffect(() => {
    if (typeof window != undefined) {
      setIsMobile(window.innerWidth >= 1024 ? false : true)
      window.addEventListener('resize', setScreen)
    }

    return () => {
      window.removeEventListener('resize', setScreen)
    }
  }, [])

  return (
    <>
      <div className="mt-20">
        <div className="grid grid-cols-1 lg:grid-cols-2 grid-section gap-0 md:gap-2.5 mb-5 lg:mb-24">
          <ProductDetails productContent={props.contentBlock} contentHeight={contentHeight} />
          {!isMobile && (
            <div className="hidden lg:block">
              <div className="grid grid-cols-2 gap-2.5">
                {props.products.length > 0 &&
                  props.products.map((product, index) => {
                    if (index < 2) {
                      return (
                        <ProductUpCard
                          product={product}
                          type={'recommendation-product'}
                          productAlt={props.productsAlt[index]}
                          setHeight={(value) => setContentHeight(value)}
                          key={index}
                          perPage={4}
                        />
                      )
                    }
                  })}
              </div>
            </div>
          )}
        </div>
        {!isMobile && (
          <div className="hidden grid-cols-4 lg:grid justify-center gap-2.5 mb-24">
            {props.products.length > 0 &&
              props.products.map((product, index) => {
                if (index >= 2 && index < 6) {
                  return (
                    <ProductUpCard
                      product={product}
                      type={'recommendation-product'}
                      productAlt={props.productsAlt[index]}
                      key={index}
                      perPage={4}
                    />
                  )
                }
              })}
          </div>
        )}
        <div className="lg:hidden mt-5">
          {' '}
          {props.products.length > 0 && (
            <ProductSlider
              products={props.products}
              productsAlt={props.productsAlt}
              sys={props.sys}
              type={'recommendation-product'}
              perPage={4}
            />
          )}
        </div>
      </div>

      <style jsx>
        {`
          @media (min-width: 830px) {
            .product-block {
              display: block;
              position: relative;
              height: fit-content;
            }
          }
        `}
      </style>
    </>
  )
}

export default ProductTextContent
