import Link from 'next/link'
import styleConfig from '@/styles/style-config'
import Icon from '@/components/icon/icon'
import { addForwardSlash, isJsonString } from '@/lib/helpers'
import { getLocalizedField, formatMetafieldValue, getLocalizedMetaField } from '@/lib/product-helper'
import { getCurrentRegion } from '@/lib/region-helper'

const region = getCurrentRegion()

const setEyebrow = (product) => {
  if (product.eyebrow) {
    return product.eyebrow
  }

  if (product.custom_fields) {
    const activity = getLocalizedField(
      product.custom_fields,
      region.localePrefix,
      'sport_activity',
      'name'
    )
    const gender = getLocalizedField(product.custom_fields, region.localePrefix, 'gender', 'name')

    if (activity && gender) {
      return `${activity.value} · ${gender.value}`
    }

    if (activity) {
      return activity.value
    }
  }
}

const ProductPanel = ({ product }) => {
  const title = product.title
  const blockBackground =
    product?.blockBackgroundColor === null ? '#EDEDED' : `#${product?.blockBackgroundColor}`
  const textColor =
    product?.textBackground === null
      ? '#000000'
      : product?.textBackground.includes('#')
      ? '#000000'
      : `#${product?.textBackground}`
  const eyebrowTextColor =
    product?.eyebrowTextColor === null ? '#525151' : `#${product?.eyebrowTextColor}`

  const defaultImage = product.images.find((image) => {
    if (isJsonString(image.description)) {
      let description = JSON.parse(image.description)

      if (description.number == '1') {
        return image
      }
    }
  })
  const fallbackImage = product.primary_image
    ? product.primary_image.url_standard
    : defaultImage
    ? defaultImage.url_standard
    : product.images[0].url_standard
  const image = product.defaultImage ? product.defaultImage.url : fallbackImage

  let description = product?.alternateDescription || null
  if(!description) {
    let benefitStatement = getLocalizedMetaField(
      product.metafields,
      region.localePrefix,
      'product_benefit_statement',
      'key'
    )

    if (benefitStatement) {
      description = formatMetafieldValue(benefitStatement.value)
    }
  }

  return (
    <>
      <div className="flex flex-col h-full">
        {image && (
          <div
            className="product-image relative bg-center bg-no-repeat bg-cover "
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        )}
        <div className="bg-gray-300 flex flex-1" style={{ backgroundColor: blockBackground }}>
          <div className="product-wrap flex flex-col flex-1">
            <p
              className="eyebrows-running font-primary font-normal text-xsm lg:text-sm text-darkGrey uppercase"
              style={{ color: eyebrowTextColor }}
            >
              {' '}
              {setEyebrow(product)}
            </p>
            <h2
              className="header-running w-auto mt-2.5 font-primary font-normal text-md  text-primaryA tracking-tight"
              style={{ color: textColor }}
            >
              {title}
            </h2>
            <p
              className="text-sm lg:text-base leading-18 lg:leading-5 mt-7 flex-auto font-primary font-normal"
              style={{ color: textColor }}
            >
              {description}
            </p>
            {product.ctaLeftUrl && (
              <Link
                href={addForwardSlash(product.ctaLeftUrl)}
                className="inline-flex border-newGray hover:border-gray-800 w-max-content action:border-black items-center group btn text-smd mr-4 rounded-full capitalize px-7"
                style={{ color: textColor }}
              >
                {product.ctaLeftText}
                {product.ctaLeftText && (
                  <Icon
                    className={`cursor-pointer w-5 ml-2 text-accent fill-current group-hover:text-primary`}
                    viewBox={`0 0 24 24`}
                    size={24}
                    icon="arrow-right"
                  />
                )}
              </Link>
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        .gap-10 {
          grid-gap: 10px;
          gap: 10px;
        }
        .eyebrows-running {
          line-height: 17px;
        }
        .header-running {
          line-height: 18px;
        }

        .product-image {
          height: 500px;
        }

        .inline-flex {
          margin-top: 40px;
        }
        .w-max-content {
          width: max-content;
        }
        .product-wrap {
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 20px;
          padding-bottom: 20px;
        }

        @media screen and (min-width: ${styleConfig.theme.screens.md}) {
          .product-image {
            height: 400px;
          }
          .header-running {
            line-height: 20px;
            font-size: 22px;
          }
        }
        @media screen and (min-width: ${styleConfig.theme.screens.lg}) {
          .product-image {
            height: 500px;
          }
          .header-running {
            font-size: 28px;
            line-height: 26px;
          }
          .product-wrap {
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 30px;
            padding-bottom: 30px;
          }
          .inline-flex {
            margin-top: 52px;
          }
          .eyebrows-running {
            line-height: 18px;
          }
        }
        @media screen and (min-width: ${styleConfig.theme.screens.xl}) {
          .product-image {
            height: 600px;
          }
          .header-running {
            line-height: 30px;
            font-size: 32px;
          }
          .inline-flex {
            margin-top: 60px;
          }
        }
      `}</style>
    </>
  )
}

const Product2Up = ({ products }) => {
  return (
    <>
      <div className="grid grid-cols-1 gap-5 lg:gap-2.5 mb-7 lg:grid-cols-2">
        {products.map((product) => (
          <ProductPanel key={product.id} product={product} />
        ))}
      </div>
    </>
  )
}
export default Product2Up
