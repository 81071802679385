import ProductUpCard from '@/components/product/product-up-card'
import ProductSlider from '@/components/product/product-slider'

const ProductLayout3Up = (props) => {

  return (
    <>
      <div className="hidden grid-cols-3 gap-5 sm:grid">
        {
          props.products.length > 0 &&
          props.products.map((product, index) => {
            if (index <= 3) {
              return <ProductUpCard
                        product={ product }
                        productAlt={ props.productsAlt[index]}
                        key={ index }/>;
            }
          })
        }
      </div>
      <div className="sm:hidden">
        <ProductSlider {...props} perPage={ 3 }/>
      </div>
    </>
  )
}

export default ProductLayout3Up;