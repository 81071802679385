import { useState, useEffect } from 'react'
import Product2Up from '@/components/product/product-2up'
import ProductLayout3Up from '@/components/product/product-layout-3up'
import ProductLayout4Up from '@/components/product/product-layout-4up'
import ProductSlider from '@/components/product/product-slider'
import ProductTextContent from '@/components/product/product-text-content'
import styleConfig from '@/styles/style-config'
import { getPersonalizedRecommendation } from '@/lib/search-spring'
import { useRouter } from 'next/router'
import { prepareCollectionData } from '@/lib/product-helper'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import Product3upAccountRecommendation from '@/components/product/product-3up-account-recommendation'
import { useSelector } from 'react-redux'
import { productView } from '@/lib/intellisuggest'

const ProductUp = (props) => {
  const router = useRouter()
  const { profile } = useSelector((state) => state.userReducer)
  const productsData = typeof props['productData'] !== 'undefined' ? props.productData : []
  const [products, setProducts] = useState([])
   
  useEffect(() => {
    let controller = new AbortController()
    let signal = controller.signal
    const setProductsData = async () => {
      if (props.searchspring && props.searchspringProfileTag) {
        let customerId = profile.bigCommCustomerID ? profile.bigCommCustomerID : null
        let recommendationTags = props.searchspringProfileTag
          ? props.searchspringProfileTag
          : 'customers-also-viewed'
        let pathOnly = router.asPath.split('?')[0]
        let checkSku = pathOnly.split('-')
        let isSKU = /^\d{6}$/.test(checkSku[checkSku.length - 1])
        let sku = isSKU ? checkSku[checkSku.length - 1] : null
        let limit = props.layout === 'text content' ? 6 : 10

        // add product view intellisugeest here to get the updated SSproductview cookie value in getPersonalizedRecommendation api call
        if (IntelliSuggest && IntelliSuggest?.context != `Product/${sku}` && recommendationTags === 'pdp-similar-products') {
          productView({ sku: sku }, customerId || 0)
        }
        
        let { results } = await getPersonalizedRecommendation(
          recommendationTags,
          sku,
          customerId,
          limit
        )
        let recommendedSkus = results.map((item) => {
          return {
            sku: item?.attributes?.sku || item?.mappings?.core?.sku,
          }
        })
        let newProductsData = await prepareCollectionData(recommendedSkus)
        // newProductsData = newProductsData.reverse()
        setProducts(newProductsData)
      } else {
        let recommendedSkus = productsData.map((item) => {
          return {
            sku: item?.sku,
          }
        })
        let newProductsData = await prepareCollectionData(recommendedSkus)
        setProducts(newProductsData)
      }
    }
    setProductsData()
    return () => {
      setProducts([])
      controller.abort()
    }
  }, [router.asPath])

  const productsAlt = props?.productsCollection?.items
  return (
    <>
      <div
        className={
          props.displayTitle && props.layout !== '3 up account recommendation'
            ? `product-up container-xl my-10 md:my-16 xl:my-20 `
            : `${
                props.layout !== '3 up account recommendation' ? 'product-up container-xl' : ''
              } my-10 md:my-20 lg:my-24 xl:my-32 `
        }
        style={{ minHeight: '500px' }}
      >
        {products.length > 0 && (
          <>
            {props.displayTitle && props.layout !== '3 up account recommendation' && (
              <div className={`${props.layout === '4 up slider' ? 'slider-m-b' : 'trend-m-b'}`}>
                <h3 className="product-up__title font-primary uppercase font-normal">
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>{props.title}</ReactMarkdown>
                </h3>
              </div>
            )}
            {props.layout === '2 up' && <Product2Up products={products} />}
            {props.layout === '3 up' && (
              <ProductLayout3Up products={products} productsAlt={productsAlt} sys={props.sys} />
            )}
            {props.layout === '3 up account recommendation' && (
              <Product3upAccountRecommendation
                products={products}
                productsAlt={productsAlt}
                sys={props.sys}
              />
            )}
            {props.layout === '4 up' && (
              <ProductLayout4Up products={products} productsAlt={productsAlt} sys={props.sys} />
            )}
            {props.layout === '4 up slider' && (
              <ProductSlider
                products={products}
                productsAlt={productsAlt}
                sys={props.sys}
                perPage={4}
              />
            )}
            {props.layout === 'text content' && (
              <ProductTextContent
                products={products}
                contentBlock={props.contentBlock}
                productsAlt={productsAlt}
                sys={props.sys}
                perPage={4}
              />
            )}
          </>
        )}
      </div>
      <style jsx>
        {`
          @media (min-width: ${styleConfig.theme.screens.md}) {
            .product-up {
              margin-top: 50px;
            }
          }

          @media (min-width: ${styleConfig.theme.screens.lg}) {
            .product-up {
              margin-top: 68.5px;
            }
          }

          @media (min-width: ${styleConfig.theme.screens.xl}) {
            .product-up {
              margin-top: 94.5px;
            }
          }

          .product-up {
            padding-left: 24px;
            padding-right: 24px;
          }

          .trend-m-b,
          .slider-m-b {
            margin-bottom: 20px;
          }

          .product-up__title {
            font-size: 20px;
            line-height: 18px;
            letter-spacing: -0.5px;
          }

          @media (min-width: ${styleConfig.theme.screens.md}) {
            .product-up__title {
              font-size: 22px;
              line-height: 20px;
            }
          }

          @media (min-width: ${styleConfig.theme.screens.lg}) {
            .product-up {
              padding-left: 15px;
              padding-right: 15px;
            }

            .product-up__title {
              font-size: 28px;
              line-height: 26px;
            }

            .text-28 {
              font-size: 28px;
            }

            .leading-26 {
              line-height: 26px;
            }

            .slider-m-b {
              margin-bottom: 28.5px;
            }
          }

          @media (min-width: ${styleConfig.theme.screens.xl}) {
            .product-up {
              padding-left: 59px;
              padding-right: 59px;
            }

            .product-up__title {
              font-size: 32px;
              line-height: 30px;
            }

            .text-32 {
              font-size: 32px;
            }

            .leading-30 {
              line-height: 30px;
            }

            .slider-m-b {
              margin-bottom: 34.5px;
            }
          }
        `}
      </style>
    </>
  )
}

export default ProductUp
